import React, { useEffect, useState } from "react";
import {
  Card,
  Form,
  Button,
  Modal,
  InputNumber,
  Input,
  Row,
  Col,
  Select,
  Typography,
  Spin,
} from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import {
  HomeOutlined,
  EditTwoTone,
  DeploymentUnitOutlined,
} from "@ant-design/icons";
import { createAddon, showAddons, updateAddon } from "../../../network/network";
import CustomBreadcrumb from "../../../components/CustomBreadcrumb";
import NotificationWithIcon from "../../../components/NotificationWithIcon";
import { useTranslation } from "react-i18next";
import ImageUpload from "../../../components/ImageUpload";
import { currencyFormatter } from "../../../utils";

const { Text } = Typography;

const Addons = () => {
  const ADD_ADDON = "Add Addon";
  const [modalTitle, setModalTitle] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [reloadTableData, setReloadTableData] = useState(false);
  const [addonData, setAddonData] = useState([]);
  const [image, setImage] = useState(false);
  const [addonIcon, setAddonIcon] = useState(false);
  const { t } = useTranslation();
  const [addonForm] = Form.useForm();

  useEffect(() => {
    setIsDataLoading(true);
    showAddons()
      .then((response) => {
        setAddonData(response.data.data.addons);
      })
      .finally(() => {
        setIsDataLoading(false);
      });
  }, [reloadTableData]);

  const handleAPIResponse = (data, type) => {
    if (data.success === true) {
      setReloadTableData(!reloadTableData);
      // Success
      if (type === ADD_ADDON) {
        NotificationWithIcon(
          "success",
          t("addon_added"),
          t("addon_added_successfully")
        );
      } else {
        NotificationWithIcon(
          "success",
          t("addon_updated"),
          t("addon_updated_successfully")
        );
      }
    } else {
      // Error
      NotificationWithIcon("error", t("something_went_wrong"), data.message);
    }
    // Reset Form Fields
    addonForm.resetFields();
    // Hide Modal
    setModalVisible(false);
  };

  const handleAddEdit = () => {
    addonForm
      .validateFields()
      .then((values) => {
        // Set Loading True
        setLoading(true);
        // Set Form values as FormData
        const formData = new window.FormData();
        if (typeof image == "object" && image) {
          formData.append("image", image);
        }
        if (typeof addonIcon == "object" && addonIcon) {
          formData.append("icon", addonIcon);
        }
        Object.keys(values).map((key) => {
          formData.append(key, values[key]);
          return true;
        });

        if (modalTitle === ADD_ADDON || modalTitle === t("addon_add")) {
          createAddon(formData)
            .then((response) => {
              let data = response.data || response.response.data;
              if (data.success === true) {
                return handleAPIResponse(data, ADD_ADDON);
              } else {
                let errors = data?.data?.errors;
                if (errors && Object.keys(errors).length) {
                  // Error
                  NotificationWithIcon(
                    "error",
                    t("something_went_wrong"),
                    errors[Object.keys(errors)[0]]
                  );
                }
              }
            })
            .catch((info) => {
              console.log("API Failed:", info.response);
              // Error
              NotificationWithIcon(
                "error",
                t("something_went_wrong"),
                info.response.data.data.errors.name
              );
            })
            .finally(() => {
              // Set Loading False
              setLoading(false);
            });
        } else {
          updateAddon(formData)
            .then((response) => {
              let data = response.data || response.response.data;
              if (data.success === true) {
                return handleAPIResponse(data, null);
              } else {
                let errors = data?.data?.errors;
                if (errors && Object.keys(errors).length) {
                  // Error
                  NotificationWithIcon(
                    "error",
                    t("something_went_wrong"),
                    errors[Object.keys(errors)[0]]
                  );
                }
              }
            })
            .catch((info) => {
              console.log("API Failed:", info.response);
              // Error
              NotificationWithIcon(
                "error",
                t("something_went_wrong"),
                info.response.data.message
              );
            })
            .finally(() => {
              // Set Loading False
              setLoading(false);
            });
        }
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const handleEditModal = (addon) => {
    addonForm.resetFields();
    addon.image ? setImage(addon.image) : setImage(false);
    addon.icon ? setAddonIcon(addon.icon) : setAddonIcon(false);
    setModalTitle(t("menu_addons") + " : " + addon.name);
    // Set Form Fields
    addonForm.setFieldsValue({
      id: addon.id,
      name: addon.name,
      price: addon.price,
      discount: addon.discount,
      billing_cycle: 3,
      trial_validity_days: addon.trial_validity_days,
      description: addon.description,
    });
    setModalVisible(true);
  };

  const handleCancel = () => {
    // Reset Form Fields
    setImage(false);
    setAddonIcon(false);
    addonForm.resetFields();
    setModalVisible(false);
  };

  const showModal = () => {
    // Reset Image Field
    setImage(false);
    setAddonIcon(false);
    // Set Modal Title
    setModalTitle(t("addon_add"));
    // Reset Form Fields
    addonForm.resetFields();
    addonForm.setFieldsValue({
      billing_cycle: 3,
    });
    // Show Modal
    setModalVisible(true);
  };

  const breadcrumbRoutes = [
    {
      path: "/",
      breadcrumbName: t("menu_home"),
      icon: <HomeOutlined />,
    },
    {
      path: "addons",
      breadcrumbName: t("menu_addons"),
      icon: <DeploymentUnitOutlined />,
    },
  ];

  return (
    <>
      <Card style={{ marginBottom: "20px" }}>
        <CustomBreadcrumb items={breadcrumbRoutes} />
        <PageHeader
          style={{ padding: "0" }}
          title={t("menu_addons")}
          ghost={false}
          extra={[
            <Button key="open-addon-modal" type="primary" onClick={showModal}>
              {t("addon_add")}
            </Button>,
          ]}
        />
      </Card>
      <Spin spinning={isDataLoading}>
        <Row gutter={16}>
          {addonData.map((addon) => {
            return (
              <Col
                className="gutter-row"
                xs={24}
                sm={12}
                md={8}
                lg={6}
                xl={6}
                key={addon.id}
              >
                <Card
                  style={{ marginTop: 10 }}
                  cover={
                    <img
                      alt={addon.name}
                      src={
                        addon.image
                          ? addon.image
                          : "/images/image-not-found.png"
                      }
                      style={{
                        width: "100%",
                        height: "200px",
                        objectFit: "fill",
                      }}
                    />
                  }
                >
                  <div
                    style={{
                      width: "fit-content",
                      display: "flex",
                      boxShadow: "0px 0px 3px #2828282E",
                      borderRadius: "35rem",
                      padding: "10px",
                      position: "absolute",
                      backgroundColor: "#FFFFFF",
                      top: "10px",
                      right: "10px",
                    }}
                  >
                    <img
                      src={addon.icon}
                      alt="addon"
                      style={{ width: "50px", height: "auto" }}
                    />
                  </div>
                  <h3>{addon.name}</h3>
                  <div
                    style={{
                      marginBottom: "10px",
                    }}
                  >
                    {addon.discount > 0 ? (
                      <>
                        <small>
                          <Text
                            delete
                            type="danger"
                            style={{
                              fontWeight: "bold",
                            }}
                          >
                            {currencyFormatter.format(addon.price)}
                          </Text>
                        </small>
                        <span
                          style={{
                            color: "#8629ff",
                            fontWeight: "bold",
                          }}
                        >
                          {" " +
                            currencyFormatter.format(
                              addon.price - addon.discount
                            )}
                        </span>
                      </>
                    ) : (
                      <span
                        style={{
                          color: "#8629ff",
                          fontWeight: "bold",
                        }}
                      >
                        {currencyFormatter.format(addon.price)}
                      </span>
                    )}
                  </div>
                  <div
                    style={{
                      marginBottom: "10px",
                      fontWeight: "bold",
                    }}
                  >
                    {t("trial_days")} : {addon.trial_validity_days}
                  </div>
                  <div
                    style={{
                      marginBottom: "10px",
                    }}
                  >
                    {addon.description}
                  </div>
                  <Button
                    type="primary"
                    size="small"
                    onClick={() => handleEditModal(addon)}
                  >
                    <EditTwoTone twoToneColor="#fff" /> {t("edit")}
                  </Button>
                </Card>
              </Col>
            );
          })}
        </Row>
      </Spin>
      <Modal
        open={modalVisible}
        title={modalTitle}
        onOk={handleAddEdit}
        onCancel={handleCancel}
        footer={[
          <Button key="back" loading={loading} onClick={handleCancel}>
            {t("cancel")}
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleAddEdit}
          >
            {modalTitle === ADD_ADDON || modalTitle === t("addon_add")
              ? t("addon_add")
              : t("update")}
          </Button>,
        ]}
      >
        <Form
          name="add-addon-form"
          layout="vertical"
          onFinish={handleAddEdit}
          style={{ width: "100%", margin: "0" }}
          form={addonForm}
        >
          <div className="ant-col ant-form-item-label">
            <label className="ant-form-item" title={t("image")}>
              {t("image")}
            </label>
          </div>
          <ImageUpload
            name="image"
            callbackSetImageFile={setImage}
            image={image}
          />

          <div className="ant-col ant-form-item-label">
            <label className="ant-form-item" title={t("icon")}>
              {t("icon")}
            </label>
          </div>
          <ImageUpload
            name="icon"
            callbackSetImageFile={setAddonIcon}
            image={addonIcon}
          />

          <Form.Item
            name="name"
            label={t("name")}
            rules={[
              {
                required: true,
                message: t("validate_name_required"),
              },
            ]}
            hasFeedback
          >
            <Input />
          </Form.Item>
          <Row gutter={[20, 20]}>
            <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={8}>
              <Form.Item
                name="price"
                label="Daily Price"
                rules={[
                  {
                    required: true,
                    message: t("validate_price_required"),
                  },
                ]}
                hasFeedback
              >
                <InputNumber style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={8}>
              <Form.Item
                name="discount"
                label={t("discount")}
                rules={[
                  {
                    required: true,
                    message: t("validate_discount_required"),
                  },
                ]}
                hasFeedback
              >
                <InputNumber style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={8}>
              <Form.Item
                name="trial_validity_days"
                label={t("trial_days")}
                rules={[
                  {
                    required: true,
                    message: t("validate_trial_days_required"),
                  },
                ]}
                hasFeedback
              >
                <Select
                  showSearch
                  allowClear
                  options={[
                    {
                      label: 0,
                      value: 0,
                    },
                    {
                      label: 30,
                      value: 30,
                    },
                    {
                      label: 60,
                      value: 60,
                    },
                    {
                      label: 90,
                      value: 90,
                    },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            name="description"
            label={t("description")}
            rules={[
              {
                required: true,
                message: t("validate_description_required"),
              },
            ]}
            hasFeedback
          >
            <Input.TextArea rows={3} />
          </Form.Item>
          <Form.Item name="billing_cycle" style={{ display: "none" }}>
            <input type="hidden" />
          </Form.Item>
          <Form.Item name="id" style={{ display: "none" }}>
            <input type="hidden" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default Addons;
